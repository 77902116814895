import React, { useEffect, useRef, useState } from "react";
import { Element, Events, scrollSpy } from "react-scroll";
import ApplicationHelmet from "../components/Helmet";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import logo from "../images/emobi.svg";
import { Link as RouteLink } from "gatsby";

export const menuLinks = [
  {
    id: 1,
    title: "Home",
    link: "/home",
    to: "homePage",
  },
  {
    id: 2,
    title: "Products",
    subLink: true,
  },
  {
    id: 3,
    title: "About",
    link: "/about",
    to: "aboutPage",
  },
  {
    id: 4,
    title: "Press Release",
    link: "/press-release",
    to: "pressRelease",
  },
  {
    id: 5,
    title: "Contact",
    link: "/contact",
    to: "contactPage",
  },
];

const StaticLayout = ({ children, isNotHomePage }) => {
  const containerRef = useRef(null);
  const [navBackground, setNavBackground] = useState("appBarTransparent");
  const [headerSticky, setHeaderSticky] = useState(false);

  const handleScroll = (e) => {
    const show = containerRef.current.childBindings.domNode.scrollTop > 90;
    if (show) {
      setNavBackground("appBarSolid");
      setHeaderSticky(true);
    } else {
      setNavBackground("appBarTransparent");
      setHeaderSticky(false);
    }
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", () => {});
    Events.scrollEvent.register("end", () => {});
    scrollSpy.update();

    return () => {
      Events.scrollEvent.register("begin", () => {});
      Events.scrollEvent.register("end", () => {});
      scrollSpy.update();
      window.removeEventListener("load", function () {
        console.log("2");
      });
    };
  }, []);

  return (
    <Element
      style={{
        overflowY: "auto",
        maxHeight: "101vh",
      }}
      id="scrollElement"
      ref={containerRef}
      onScroll={handleScroll}
    >
      <ApplicationHelmet />
      <div className="header-static">
        <Navbar
          fixed={headerSticky ? "top" : ""}
          className={`${navBackground} block-header`}
          bg="transparent"
        >
          <Container>
            <Row className="align-items-center">
              <Col md={2} xs={10} lg={2}>
                <Navbar.Brand>
                  <RouteLink
                    to="/"
                  >
                    <img src={logo} alt="EMOBI" title="EMOBI" />
                  </RouteLink>
                </Navbar.Brand>
              </Col>
              <Col className="d-none d-lg-block">
                {isNotHomePage ? (
                  <Nav className="nav-center justify-content-end">
                    <RouteLink to="/">Home</RouteLink>
                  </Nav>
                ) : (
                  <Nav className="nav-center justify-content-end">
                    <RouteLink to="/">Home</RouteLink>
                  </Nav>
                )}
              </Col>
            </Row>
          </Container>
        </Navbar>
      </div>
      {children}
    </Element>
  );
};

export default StaticLayout;
